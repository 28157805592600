<template>
  <!-- Detail Search -->
  <div id="tableSearchAccordion" @keydown.enter="enterKey">
    <div class="card detail-card mb-3">
      <div
        class="card-header detail-header"
        v-bind:class="headerClass"
        id="headingOne"
      >
        <div class="row align-items-center">
          <h2 class="col mb-0">
            <button
              class="font-weight-bold btn btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#tableDetailSearch"
              aria-expanded="true"
              aria-controls="tableDetailSearch"
              style="color: #495057"
            >
              <i class="fas fa-search mr-2" v-if="showIcon"></i>
              <span v-bind:class="headerTextClass">Detaylı Arama</span>
            </button>
          </h2>
          <div class="col-auto">
            <i class="fas fa-chevron-down ml-auto"></i>
          </div>
        </div>
      </div>
      <!-- Search Body -->
      <div
        id="tableDetailSearch"
        class="collapse"
        v-bind:class="{ show: showOnLoad }"
        aria-labelledby="headingOne"
        data-parent="#tableSearchAccordion"
      >
        <div class="card-body">
          <!-- Body -->
          <slot name="detail-search"></slot>
          <!-- Body -->
          <div class="row">
            <div class="col-auto">
              <span class="btn btn-primary btn-sm" v-on:click="$emit('search')"
                >Filtrele</span
              >
            </div>
            <div
              class="col-auto"
              v-bind:class="{ 'p-0': exportable, 'pl-0': !exportable }"
              v-if="clearEnable"
            >
              <span class="btn btn-danger btn-sm" v-on:click="$emit('clear')"
                >Temizle</span
              >
            </div>
            <div class="col-auto" v-if="exportable">
              <span class="btn btn-success btn-sm" v-on:click="onExport"
                >Excel'e Aktar</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Search Body -->
    </div>
  </div>
  <!-- Detail Search -->
</template>
<script>
export default {
  name: "WeTableSearch",
  props: {
    clearEnable: {
      default: true,
    },
    showOnLoad: {
      default: false,
    },
    headerClass: {
      default: "",
    },
    headerTextClass: {
      default: "",
    },
    showIcon: {
      default: true,
    },
    exportable: {
      default: false,
    },
  },
  methods: {
    onExport() {
      this.$emit("on-export");
    },
    enterKey() {
      this.$emit("search");
    },
  },
};
</script>
<style lang="scss">
.detail-card {
  border-color: #dee2e6;
}

.detail-header {
  background: #e9ecef;
}
</style>
